<template>
  <section class="rims-config_carTag-tpms">
    <rimconfig-breadcrumb
      :breadcrumb-items="breadcrumbItems"
      @set-car-selector-step="setCarSelectorStep"
      @on-click-wheels="onClickWheels"
      @on-click-wheel-detail="onClickWheelDetail"
    />
    <rimconfig-select-history-bar />
    <base-rimconfig-filter-bar
      has-checkbox
      has-reset
      has-select
      @reset="onResetFilter"
    >
      <template #checkbox-group>
        <div class="configurator-car-checkbox-list">
          <base-checkbox
            v-for="(tpmsGroup, index) in tpmsGroupFilterCheckboxes"
            :key="`${index}`"
            v-model="inputGroupFilter[tpmsGroup]"
            :label-text="$t(tpmsGroup)"
            class="checkbox-item"
            @input="onSelectCheckboxFilter(inputGroupFilter[tpmsGroup], tpmsGroup)"
          />
        </div>
      </template>
      <template #select-group>
        <div class="configurator-car-dropdown-list">
          <base-select-filter-dropdown
            v-for="(selectField, index) in selectFilterFields"
            :key="`select${selectField.key}${index}`"
            v-model="inputSelectFilter[selectField.key]"
            :placeholder="$t(selectField.placeholder)"
            :select-label="inputSelectFilter[selectField.key] && inputSelectFilter[selectField.key].length > 1 ? selectField.pluralSelectLabel : selectField.singleSelectLabel"
            :option-items="selectFilterOptions[selectField.key] || []"
            @input="onSelectDropdownFilter($event, selectField.key)"
          />
        </div>
      </template>
    </base-rimconfig-filter-bar>
    <div class="tpms-table">
      <v-container>
        <v-data-table
          class="primary-style"
          :headers="tableFields"
          :items="filteredTpmsData"
          :loading="isLoading"
          :custom-sort="customSort"
          sort-by="bestPrice"
          disable-pagination
          hide-default-footer
        >
          <template
            v-for="(field, index) in tableFields"
            v-slot:[`header.${field.value}`]="{ header }"
          >
            <div
              :key="`${field.value}${index}`"
              class="d-flex"
              :class="field.value !== 'description1' ? 'align-center justify-center' : null"
            >
              <span>{{ $t(header.text) }}</span>
              <div class="icon__sort" />
            </div>
          </template>
          <template v-slot:[`item.image`]="{ item }">
            <img
              v-if="item.imageTemplate"
              v-lazy="getTpmsImageBySize(item.imageTemplate, 80, 80)"
              class="tpms-img"
            >
          </template>
          <template v-slot:[`item.description1`]="{ item }">
            <p class="text-left">
              {{ item.description1 }} {{ item.description2 }}
            </p>
          </template>
          <template v-slot:[`item.purchasePrice`]="{ item }">
            {{ item.prices.bestPrice.purchasePrice | price }}
          </template>
          <template v-slot:[`item.retailPrice`]="{ item }">
            {{ item.prices.bestPrice.retailPrice | price }}
          </template>
          <template v-slot:[`item.retailTaxPrice`]="{ item }">
            {{ item.prices.bestPrice.retailTaxPrice | price }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              plain
              class="button__input-clear"
              @click="order(item)"
            >
              <icon-add-to-cart />
            </v-btn>
          </template>
        </v-data-table>
      </v-container>
    </div>
    <base-add-to-cart-dialog
      ref="addToCartDialog"
      is-rim-config
      :cart-items="cartItems"
      :select-quantity-table="selectQuantity"
      supplier-source
      @on-subtract-quantity="onSubtractQuantity"
      @on-add-quantity="onAddQuantity"
      @on-update-quantity="onUpdateQuantity"
      @supplier-add-cart="onSupplierAddToCart"
    />

    <v-dialog
      v-model="showContinueShoppingOrShowCartDialog"
      max-width="1000"
    >
      <v-card class="dialog wheel-detail">
        <v-card-title>
          <h5 class="text">
            {{ $t('langkey.cart-dialog-head') }}
          </h5>
        </v-card-title>
        <v-card-text>
          <v-data-table
            class="primary-style select-wheel-table"
            :headers="tableFieldSelectedItem"
            :items="selectedItems.displayCartItems"
            hide-default-footer
            disable-sort
          >
            <template
              v-for="(field, index) in tableFieldSelectedItem"
              v-slot:[`header.${field.value}`]="{ header }"
            >
              <span :key="`${field.value}${index}`">{{ $t(header.text) }}</span>
            </template>
            <template v-slot:[`item.image`]="{ item }">
              <img
                v-if="item.isWheel"
                v-show="item.picturePerspective"
                v-lazy="getWheelImageBySize(item.picturePerspective)"
                class="cart-img"
              >
              <img
                v-else-if="item.isTpms"
                v-show="item.imageTemplate"
                v-lazy="getTpmsImageBySize(item.imageTemplate)"
                class="cart-img"
              >
            </template>
            <template v-slot:[`item.description`]="{ item }">
              <div v-if="item.isWheel">
                <div
                  v-if="item.isCombination"
                  class="combination"
                >
                  <div>{{ item.F.description }}</div>
                  <div class="mt-3">
                    {{ item.R.description }}
                  </div>
                </div>
                <span v-else>{{ item.description }}</span>
              </div>
              <div v-else-if="item.isTpms">
                {{ item.description }}
              </div>
            </template>
            <template v-slot:[`item.articleId`]="{ item }">
              <div v-if="item.isWheel">
                <div
                  v-if="item.isCombination"
                  class="combination"
                >
                  <div>{{ item.F.man }}</div>
                  <div class="mt-3">
                    {{ item.R.man }}
                  </div>
                </div>
                <span v-else>{{ item.man }}</span>
              </div>
              <div v-else-if="item.isTpms">
                {{ item.articleNumber }}
              </div>
            </template>
            <template v-slot:[`item.quantity`]="{ item, index }">
              <div class="qty__wrapper">
                <div v-if="item.isWheel">
                  <v-btn
                    :disabled="selectedItems.wheel.quantity < 2"
                    icon
                    plain
                    @click="onSubtractQuantitySelectedWheel"
                  >
                    <icon-subtract />
                  </v-btn>
                  <input
                    v-model.number="selectedItems.wheel.quantity"
                    class="qty__input"
                    @change="onUpdateQuantitySelectedWheel($event, index)"
                  >
                  <v-btn
                    :disabled="selectedItems.wheel.quantity >= item.stock"
                    icon
                    plain
                    @click="onAddQuantitySelectedWheel"
                  >
                    <icon-add />
                  </v-btn>
                </div>
                <div v-else-if="item.isTpms">
                  <v-btn
                    :disabled="selectedItems.tpms.quantity < 2"
                    icon
                    plain
                    @click="onSubtractQuantitySelectedTpms"
                  >
                    <icon-subtract />
                  </v-btn>
                  <input
                    v-model.number="selectedItems.tpms.quantity"
                    class="qty__input"
                    @change="onUpdateQuantitySelectedTpms($event, index)"
                  >
                  <v-btn
                    :disabled="selectedItems.tpms.quantity >= item.stock"
                    icon
                    plain
                    @click="onAddQuantitySelectedTpms"
                  >
                    <icon-add />
                  </v-btn>
                </div>
              </div>
            </template>
            <template v-slot:[`item.total`]="{ item }">
              <span v-if="item.isWheel">
                {{ item.prices.bestPrice[priceType] * selectedItems.wheel.quantity | price }}
              </span>
              <span v-else-if="item.isTpms">
                <!-- <span v-html="item" /> -->
                {{ item.price[priceType] * selectedItems.tpms.quantity | price }}
              </span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <div class="d-flex flex-column align-center">
            <p class="fitted-wheel-text">
              {{ $t('langkey.proceed-with-fitted-wheel') }}
            </p>
            <v-btn
              class="icon"
              color="primary"
              @click="continueToTyre"
            >
              {{ $t('langkey.skip-to-tyres') }} <icon-arrow color="var(--theme-white)" />
            </v-btn>
          </div>
          <v-btn
            class="icon show-cart"
            outlined
            plain
            @click="onShowCart"
          >
            {{ $t('langkey.cart-dialog-confirm') }} <icon-cart-show color="var(--theme-black)" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<style lang="scss">
@import "@/styles/pages/rims-config_carTag-tpms.scss";
</style>

<script>
import RimconfigBreadcrumb from '@/components/RimconfigBreadcrumb';
import RimconfigSelectHistoryBar from '@/components/RimconfigSelectHistoryBar';
import IconCartShow from '@/components/icons/IconCartShow';
import IconArrow from '@/components/icons/IconArrow';
import IconSubtract from '@/components/icons/IconSubtract';
import IconAdd from '@/components/icons/IconAdd';
import { breadcrumbItemsNoState, tableFields, tableFieldSelectedItem, selectFilterFields, tableFieldsRetailPrice } from './configs.js';
import { mapState } from 'vuex';
import { getImageBySize } from '@/utils/index.js';

export default {
  components: {
    RimconfigBreadcrumb,
    RimconfigSelectHistoryBar,
    IconCartShow,
    IconArrow,
    IconSubtract,
    IconAdd,
  },
  data() {
    return {
      isLoading: false,
      cartItems: [],
      selectedCarWheel: {},
      selectQuantity: {},

      showContinueShoppingOrShowCartDialog: false,

      selectedItems: {
        wheel: {
          isCombination: false,
          assignIds: [],
          quantity: 0,
          cartItems: [],
        },
        tpms: {
          assignId: 0,
          quantity: 0,
          cartItems: [],
        },
        displayCartItems: [],
      },
      tpmsGroupFilterCheckboxes: [],
      inputGroupFilter: {},
      inputSelectTpmsKeyFilter: [],
      inputSelectTpmsValueFilter: {
        group: [],
      },
      selectFilterOptions: {
        manufacturer: [],
      },
      inputSelectFilter: {},
    };
  },
  computed: {
    ...mapState('rimconfigTpms', [
      'tpmsAssignByTpmsTag',
    ]),
    ...mapState('rimconfigCarConfigurator', [
      'tpmsData',
      'carData',
      'selectedConfiguratorItems',
    ]),
    ...mapState('pricing', ['priceType']),
    breadcrumbItems() {
      return breadcrumbItemsNoState;
    },
    tableFields() {
      if (this.priceType === 'retailPrice' || this.priceType === 'retailTaxPrice') {
        return tableFieldsRetailPrice;
      } else {
        return tableFields;
      }
    },
    getCarTag() {
      return this.$route.params.carTag;
    },
    getHsnTsnVsn() {
      return this.$route.params.hsnTsnVsn;
    },
    getSelectedWheel() {
      // /rims-config/configurator/1230532503452/tpms/wheelArticleNumber__4
      // wheelArticleNumber__4

      // combination
      // /rims-config/configurator/1230532503452/tpms/wheelArticleNumberF__2+wheelArticleNumberR__2
      // wheelArticleNumberF__2+wheelArticleNumberR__2
      return this.$route.params.selectedWheel;
    },
    getWheelDetailParams() {
      return this.getSelectedWheel.split('__')[0];
    },
    getSelectedWheelParams() {
      return this.getSelectedWheel.split('__')[1];
    },
    tableFieldSelectedItem() {
      return tableFieldSelectedItem;
    },

    // non-combination
    getWheelArticleNumber() {
      return this.getSelectedWheelParams.split('_')[0];
    },
    getWheelQuantity() {
      return this.getSelectedWheelParams.split('_')[1];
    },

    // combination
    getFrontWheelArticleNumber() {
      return this.getSelectedWheelParams.split('+')[0].split('_')[0];
    },
    getRearWheelArticleNumber() {
      if (this.getSelectedWheelParams.split('+')[1]) {
        return this.getSelectedWheelParams.split('+')[1].split('_')[0];
      } else {
        return '';
      }
    },
    getFrontWheelQuantity() {
      return this.getSelectedWheelParams.split('+')[0].split('_')[1];
    },
    getRearWheelQuantity() {
      if (this.getSelectedWheelParams.split('+')[1]) {
        return this.getSelectedWheelParams.split('+')[1].split('_')[1];
      } else {
        return '';
      }
    },
    selectFilterFields() {
      return selectFilterFields;
    },
    filteredTpmsData() {
      if (this.inputSelectTpmsKeyFilter.length === 0) {
        return this.tpmsData;
      }
      return this.tpmsData.slice().filter((item) => {
        let filterResult = true;
        for (let key of this.inputSelectTpmsKeyFilter) {
          filterResult = this.inputSelectTpmsValueFilter[key].includes(item[key]) && filterResult;
        }
        return filterResult;
      });
    },
  },
  async created() {
    this.setInitSelectQuantity();

    this.$store.dispatch('rimconfigCarConfigurator/setSelectConfiguratorStep', 2);

    this.selectedItems.wheel.quantity = this.selectedConfiguratorItems.wheel.quantity;
    this.selectedItems.wheel.isCombination = this.selectedConfiguratorItems.wheel.isCombination;
    this.selectedItems.wheel.assignIds = [...this.selectedConfiguratorItems.wheel.assignIds];
    this.selectedItems.wheel.cartItems = [...this.selectedConfiguratorItems.wheel.cartItems];

    this.setTpmsGroupFilterCheckboxes();
    this.setTpmsGroupManufacturer();
    this.setInitInputSelectFilter();
  },
  methods: {
    async onClickWheels() {
      try {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: true,
        });
        await this.$store.dispatch('rimconfigCarSelector/setCarHistory', this.$route.params.carTag);
        await this.$store.dispatch('rimconfigCarSelector/getCarHistory');
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        this.$router.push(this.$i18nRoute({
          name: 'rims-config-configurator_carTag',
          carTag: this.$route.params.carTag,
        }));
      }
    },
    async onClickWheelDetail() {
      try {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: true,
        });
        await this.$store.dispatch('rimconfigCarSelector/setCarHistory', this.$route.params.carTag);
        await this.$store.dispatch('rimconfigCarSelector/getCarHistory');
      } catch (err) {
        console.log(err);
      } finally {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        const { manufacturer, design, color } = this.$route.query;
        this.$router.push(this.$i18nRoute({
          name: 'rims-config-configurator_carTag',
          params: {
            carTag: this.$route.params.carTag,
          },
          query: {
            wheelDetail: true,
            manufacturer,
            design,
            color,
          },
        }));
      }
    },
    getCarDetails() {
      if (this.getCarTag) {
        this.$store.dispatch('rimconfigCarConfigurator/loadCarByCarTag', {
          carTag: this.getCarTag,
        });
      } else if (this.getHsnTsnVsn) {
        const hsn = this.getHsnTsnVsn.split('/')[0];
        const tsnVsn = this.getHsnTsnVsn.split('/')[1];
        this.$store.dispatch('rimconfigCarConfigurator/getCarGroupByGermanCode', {
          hsn: hsn,
          tsn: tsnVsn.substring(0,3),
          vsn: tsnVsn.substring(3),
        });
      }
    },
    getWheelImageBySize(image) {
      if (image.includes('https://cdn.jfnet.de/tpms')) {
        return getImageBySize(image, 120, 120);
      }

      return getImageBySize(image, 120, 120);
    },
    getTpmsImageBySize(image, width = 120, height = 120) {
      if (image.includes('https://cdn.jfnet.de/tpms')) {
        return getImageBySize(image, width, height);
      }

      return getImageBySize(image, width, height).replace('https://cdn.jfnet.de/', 'https://cdn.jfnet.de/tpms/');
    },
    setInitSelectQuantity() {
      this.tpmsData.forEach((item) => {
        const itemKey = `${item.man}${item.ean}`;
        this.selectQuantity = {
          ...this.selectQuantity,
          [itemKey]: 4,
        };
      });
    },
    order(item) {
      this.cartItems = [];
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      this.$store.dispatch('rimconfigTpms/getTpmsAssigns', {
        tpmsTag: item.tpmsTag,
        assigns: item.assigns,
      })
        .then(() => {
          const tpmsAssigns = this.tpmsAssignByTpmsTag[item.tpmsTag].slice()
            .map((assignItem) => {
              return {
                ...assignItem,
                listPrice: item.prices.bestPrice,
              };
            });
          this.cartItems.push({
            ...item,
            imageTemplate: item.imageTemplate,
            description: item.description1,
            articleId: item.man,
            quantity: this.selectQuantity[`${item.man}${item.ean}`] || 1,
            price: item.price || 0,
            suppliers: tpmsAssigns,
          });
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
          this.$refs.addToCartDialog.show();
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch('dialog/setLoadingDialog', {
            status: false,
          });
        });
    },
    async onSupplierAddToCart({ item, quantity }) {
      try {
        this.selectedItems.displayCartItems = [];
        this.$refs.addToCartDialog.hide();
        this.selectedItems.tpms = {
          assignId: item.id,
          quantity: quantity,
          cartItems: [{
            ...item,
            description: this.cartItems[0].description,
            imageTemplate: this.cartItems[0].imageTemplate,
          }],
        };
        await this.$store.dispatch('rimconfigCarConfigurator/selectConfiguratorTpms', this.selectedItems.tpms);
        this.selectedItems.wheel.cartItems.forEach((wheelItem) => {
          this.selectedItems.displayCartItems = [
            ...this.selectedItems.displayCartItems,
            {
              ...wheelItem,
              isWheel: true,
            }];
        });
        this.selectedItems.displayCartItems = [
          ...this.selectedItems.displayCartItems,
          {
            ...item,
            description: this.cartItems[0].description,
            imageTemplate: this.cartItems[0].imageTemplate,
            isTpms: true,
          }];

        //display another dialog
        this.showContinueShoppingOrShowCartDialog = true;
      } catch (err) {
        console.log(err);
      }
    },
    async onShowCart() {
      this.$refs.addToCartDialog.hide();
      this.$store.dispatch('dialog/setLoadingDialog', {
        status: true,
      });
      const userType = sessionStorage.getItem('userType');
      if (userType && userType.toString().toLowerCase().includes('test')) {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        this.$router.push(this.$i18nRoute({
          name: 'register-full',
        }));
        return;
      }
      try {
        // add tpms add to cart
        await this.$store.dispatch('cart/addCartItem', {
          id: this.selectedItems.tpms.assignId,
          quantity: this.selectedItems.tpms.quantity,
        });
        // add wheel to cart
        console.log('this.selectedItems.wheel', this.selectedItems.wheel);
        await this.$store.dispatch('cart/addCartItem', {
          id: this.selectedItems.wheel.assignIds[0],
          quantity: this.selectedItems.wheel.quantity,
          context: {
            kitMan: this.selectedItems.wheel.cartItems[0].kitMan || 'Serie',
            carId: this.carData.tag,
          },
        });
        if (this.selectedItems.wheel.isCombination === true) {
          await this.$store.dispatch('cart/addCartItem', {
            id: this.selectedItems.wheel.assignIds[0],
            quantity: this.selectedItems.wheel.quantity,
            context: {
              kitMan: this.selectedItems.wheel.cartItems[1].kitMan || 'Serie',
              carId: this.carData.tag,
            },
          });
        }
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        this.$router.push(this.$i18nRoute({
          name: 'cart',
        }));
      } catch (err) {
        this.$store.dispatch('dialog/setLoadingDialog', {
          status: false,
        });
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: 'Error Add item to cart',
          message: err,
        });
      }
    },
    onSubtractQuantity({ articleId, quantity }) {
      this.cartItems[0].suppliers[0].quantity = quantity;
    },
    onAddQuantity({ articleId, quantity }) {
      this.cartItems[0].suppliers[0].quantity = quantity;
    },
    onUpdateQuantity ({ articleId, quantity }) {
      this.cartItems[0].suppliers[0].quantity = quantity;
    },
    setCarSelectorStep(step) {
      const promise1 = this.$store.dispatch('rimconfigCarSelectorTpms/setStep', step);
      const promise2 = this.$store.dispatch('rimconfigCarSelectorTpms/setSearchByKbaNumber', false);
      Promise.all([promise1, promise2])
        .then(() => {
          this.$router.push(this.$i18nRoute({
            name: 'rims-config-tpms',
          }));
        });
    },
    onContinueShopping() {
      let promises = [];
      Object.keys(this.cartItemsBySupplier).forEach((key) => {
        promises.push(this.$store.dispatch('cart/addCartItem', {
          id: key,
          quantity: this.cartItemsBySupplier[key],
        }));
      });
      Promise.all(promises)
        .then(() => {
          this.$refs.addToCartDialog.hide();
        });
    },
    displayBestPrice(bestPrice) {
      return parseFloat(bestPrice.toString()).toFixed(2).replace('.', ',');
    },
    customSort(items, sortBy, isDesc) {
      const prop = sortBy[0];
      if (sortBy.length === 0 && isDesc.length === 0) { //no sorting, default list
        return items;
      } else if (prop === 'minCarBuildDate' || prop === 'maxCarBuildDate') { //sorting productionFrom & productionTo column
        items.sort((a, b) => { //sort out moving no date to show last
          if ((!a[prop] && !b[prop]) || (a[prop] && b[prop])) {
            return 0;
          } else if (a[prop] && !b[prop]) {
            return -1;
          } else {
            return 1;
          }
        });
        items.sort((a, b) => {
          let currentValue = (a[prop]) ? a[prop].split('/') : [];
          let nextValue = (b[prop]) ? b[prop].split('/') : [];
          currentValue.forEach((element) => element = Number(element));
          nextValue.forEach((element) => element = Number(element));
          let result = 0;
          if (currentValue.length === 0) { //no date showing
            return 0;
          } else if (currentValue.length === 2) { //current has month and year
            if (nextValue.length === 2) { //next has month and year
              if (currentValue[1] === nextValue[1]) { //same year
                if (currentValue[0] <= nextValue[0]) { //compare month, same year
                  result = -1;
                } else {
                  result = 1;
                }
              } else if (currentValue[1] < nextValue[1]) { //current year comes first
                result = -1;
              } else { //next year comes first
                result = 1;
              }
            } else if (nextValue.length === 1) { //next only have year
              if (currentValue[1] <= nextValue[0]) { //current year comes first or same year as next
                result = -1;
              } else { //next year comes first
                result = 1;
              }
            } else { //next has no month or year
              return 0;
            }
          } else { //current has only year
            if (nextValue.length === 2) { //next has month and year
              if (currentValue[0] >= nextValue[1]) { //current has same year as next or next year comes first
                result = 1;
              } else { //current year comes first
                result = -1;
              }
            } else if (nextValue.length === 1) { //next has only year
              if (currentValue[0] <= nextValue[0]) { //current year comes first or has same year as next
                result = -1;
              } else {
                return 0;
              }
            } else { //next has no month or year
              return 0;
            }
          }
          if (isDesc[0]) {
            result *= -1;
          }
          return result;
        });
        return items;
      } else if (prop === 'bestPrice') { //sorting for best price column
        items.sort((a, b) => {
          const currentValue = Number(a.prices.bestPrice);
          const nextValue = Number(b.prices.bestPrice);
          if (isDesc[0]) {
            if (currentValue < nextValue) {
              return 1;
            }
            if (currentValue > nextValue) {
              return -1;
            }
            return 0;
          } else {
            if (currentValue > nextValue) {
              return 1;
            }
            if (currentValue < nextValue) {
              return -1;
            }
            return 0;
          }
        });
        return items;
      } else { //default sorting
        items.sort((a, b) => {
          const currentValue = a[prop] ? a[prop].toLowerCase() : '';
          const nextValue = b[prop] ? b[prop].toLowerCase() : '';
          if (isDesc[0]) {
            if (currentValue < nextValue) {
              return 1;
            }
            if (currentValue > nextValue) {
              return -1;
            }
            return 0;
          } else {
            if (currentValue > nextValue) {
              return 1;
            }
            if (currentValue < nextValue) {
              return -1;
            }
            return 0;
          }
        });
        return items;
      }
    },
    onSubtractQuantitySelectedWheel() {
      let quantity = this.selectedItems.wheel.quantity;
      if (quantity > 0) {
        this.selectedItems.wheel.quantity = quantity - 1;
        this.$forceUpdate();
      }
    },
    onAddQuantitySelectedWheel() {
      let quantity = this.selectedItems.wheel.quantity;
      this.selectedItems.wheel.quantity = quantity + 1;
      this.$forceUpdate();
    },
    onUpdateQuantitySelectedWheel (event, index) {
      let newQuantity;
      let previousQuantity;
      previousQuantity = this.selectedItems.wheel.quantity || 0;

      if (!event.target.value) {
        this.selectedItems.wheel.quantity = previousQuantity;
        return;
      }

      const max = this.selectedItems.wheel.cartItems[index].stock;
      if (event.target.value > max) {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: this.$t('langkey.stock-limit'),
          message: `${this.$t('langkey.stock-limit-at')} ${max}`,
        });
        this.selectedItems.wheel.quantity = max;
        newQuantity = max;
      } else if (event.target.value < 0) {
        this.selectedItems.wheel.quantity = 0;
        newQuantity = 0;
      } else {
        newQuantity = parseInt(event.target.value);
      }
      this.selectedItems.wheel.quantity = newQuantity;
    },
    onSubtractQuantitySelectedTpms() {
      let quantity = this.selectedItems.tpms.quantity;
      if (quantity > 0) {
        this.selectedItems.tpms.quantity = quantity - 1;
        this.$forceUpdate();
      }
    },
    onAddQuantitySelectedTpms() {
      let quantity = this.selectedItems.tpms.quantity;
      this.selectedItems.tpms.quantity = quantity + 1;
      this.$forceUpdate();
    },
    onUpdateQuantitySelectedTpms (event, index) {
      let newQuantity;
      let previousQuantity;
      previousQuantity = this.selectedItems.tpms.quantity || 0;

      if (!event.target.value) {
        this.selectedItems.tpms.quantity = previousQuantity;
        return;
      }

      const max = this.selectedItems.tpms.cartItems[index].stock;
      if (event.target.value > max) {
        this.$store.dispatch('dialog/setShowErrorDialog', {
          status: true,
          title: this.$t('langkey.stock-limit'),
          message: `${this.$t('langkey.stock-limit-at')} ${max}`,
        });
        this.selectedItems.tpms.quantity = max;
        newQuantity = max;
      } else if (event.target.value < 0) {
        this.selectedItems.tpms.quantity = 0;
        newQuantity = 0;
      } else {
        newQuantity = parseInt(event.target.value);
      }
      this.selectedItems.tpms.quantity = newQuantity;
    },
    continueToTyre() {
      this.$router.push(this.$i18nRoute({
        name: 'rims-config-configurator_carTag-tyre',
        params: {
          carTag: this.carData.tag,
        },
        query:{
          tpms: this.selectedItems.tpms.assignId,
          tpmsQuantity: this.selectedItems.tpms.quantity,
          ...this.$route.query,
        },
      }));
    },
    onResetFilter() {
      this.setInitInputSelectFilter();
      this.inputSelectTpmsKeyFilter = [];
      for (const key in this.inputGroupFilter) {
        this.inputGroupFilter[key] = false;
      }
    },
    setTpmsGroupFilterCheckboxes() {
      const getTpmsGroups = this.tpmsData.map((item) => item.group);
      const uniqueTpmsGroups = [...new Set(getTpmsGroups)];
      this.tpmsGroupFilterCheckboxes = uniqueTpmsGroups;
      this.tpmsGroupFilterCheckboxes.forEach((item) => {
        this.inputGroupFilter = {
          ...this.inputGroupFilter,
          [item]: false,
        };
      });
    },
    setTpmsGroupManufacturer() {
      const getTpmsManufacturer = this.tpmsData
        .map((item) => item.manufacturer)
        .sort((a,b) => a.toString().localeCompare(b.toString()));
      const uniqueTpmsManufacturer = [...new Set(getTpmsManufacturer)];
      this.selectFilterOptions.manufacturer = uniqueTpmsManufacturer.map((item) => {
        return { label: item, value: item };
      });
    },
    setInitInputSelectFilter() {
      selectFilterFields.forEach((item) => {
        this.inputSelectFilter = {
          ...this.inputSelectFilter,
          [item.key]: [],
        };
        this.inputSelectTpmsValueFilter = {
          ...this.inputSelectTpmsValueFilter,
          [item.key]: [],
        };
      });
    },
    onSelectCheckboxFilter(event, group) {
      if (event) {
        if (!this.inputSelectTpmsKeyFilter.includes('group')) {
          this.inputSelectTpmsKeyFilter.push('group');
        }
        this.inputSelectTpmsValueFilter.group.push(group);
      } else {
        const tmpCurrentSelectTpmsGroupFilter = this.inputSelectTpmsValueFilter.group;
        this.inputSelectTpmsValueFilter.group = tmpCurrentSelectTpmsGroupFilter.filter((item) => item !== group);
        if (this.inputSelectTpmsValueFilter.group.length === 0) {
          const tmpCurrentTpmsKeyFilter = this.inputSelectTpmsKeyFilter;
          this.inputSelectTpmsKeyFilter = tmpCurrentTpmsKeyFilter.filter((item) => item !== 'group');
        }
      }
    },
    onSelectDropdownFilter(event, key) {
      if (event && event.length) {
        if (!this.inputSelectTpmsKeyFilter.includes(key)) {
          this.inputSelectTpmsKeyFilter.push(key);
        }
        this.inputSelectTpmsValueFilter[key] = event;
      } else {
        this.inputSelectTpmsValueFilter[key] = [];
        if (this.inputSelectTpmsValueFilter[key].length === 0) {
          const tmpCurrentTpmsKeyFilter = this.inputSelectTpmsKeyFilter;
          this.inputSelectTpmsKeyFilter = tmpCurrentTpmsKeyFilter.filter((item) => item !== key);
        }
      }
    },
  },
};
</script>