<template>
  <div class="select-history-bar">
    <div class="select-car">
      <img
        v-if="!empty(carData)"
        :src="getImagePath(carData, '250x72')"
        class="car-image"
      >
      <div class="detail">
        <p class="title">
          {{ getCarInfoTitle }}
        </p>
        <p class="sub-title">
          {{ getCarInfoSubtitle }}
        </p>
      </div>
    </div>
    <div
      v-if="!empty(selectedConfiguratorItems.wheel.cartItems)"
      class="select-wheel"
    >
      <img
        :src="getWheelImage"
        alt="wheel-img"
      >
      <div class="detail">
        <p class="title">
          {{ getWheelTitle }}
        </p>
        <p
          v-if="isWheelCombination"
          class="title"
        >
          {{ getWheelCombinationTitle }}
        </p>
      </div>
    </div>
    <div
      v-if="!empty(selectedConfiguratorItems.tpms.cartItems) && isConfiguratorTyre"
      class="select-tpms"
    >
      <img
        :src="getTpmsImage"
        alt="tpms-img"
      >
      <div class="detail">
        <p class="title">
          {{ getTpmsTitle }}
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/styles/components/rimconfig-select-history-bar.scss';
</style>

<script>
import { mapState } from 'vuex';
import { getImageBySize } from '@/utils/index.js';
import _ from 'lodash';

export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      wheelCertificateInformation: {},
    };
  },
  computed: {
    ...mapState('rimconfigCarConfigurator', [
      'carData',
      'selectedConfiguratorItems',
    ]),
    getCarInfoTitle() {
      if (!this.empty(this.carData)) {
        return `${this.carData.manufacturer} ${this.carData.class} ${this.carData.version} ${this.carData.power}KW/${this.carData.systemPower}PS`;
      } else {
        return '';
      }
    },
    getCarInfoSubtitle() {
      if (!this.empty(this.carData)) {
        return `${this.carData.typeApproval} ${this.carData.hsn} / ${this.carData.tsn} / ${this.carData.vsn}`;
      } else {
        return '';
      }
    },
    getWheelTitle() {
      if (!this.empty(this.selectedConfiguratorItems.wheel.cartItems)) {
        return `${this.selectedConfiguratorItems.wheel.cartItems[0].description}`;
      } else {
        return '';
      }
    },
    getWheelCombinationTitle() {
      if (!this.empty(this.selectedConfiguratorItems.wheel.cartItems)) {
        return `${this.selectedConfiguratorItems.wheel.cartItems[1].description}`;
      } else {
        return '';
      }
    },
    getWheelImage() {
      if (!this.empty(this.selectedConfiguratorItems.wheel.cartItems)) {
        return this.getWheelImageBySize(this.selectedConfiguratorItems.wheel.cartItems[0].picturePerspective);
      } else {
        return '';
      }
    },
    isWheelCombination() {
      return this.selectedConfiguratorItems.wheel.isCombination;
    },
    isConfiguratorTyre() {
      return this.$route.name === 'rims-config-configurator_carTag-tyre';
    },
    getTpmsImage() {
      if (!this.empty(this.selectedConfiguratorItems.tpms.cartItems)) {
        return this.getTpmsImageBySize(this.selectedConfiguratorItems.tpms.cartItems[0].imageTemplate);
      } else {
        return '';
      }
    },
    getTpmsTitle() {
      if (!this.empty(this.selectedConfiguratorItems.tpms.cartItems)) {
        return `${this.selectedConfiguratorItems.tpms.cartItems[0].description1} ${this.selectedConfiguratorItems.tpms.cartItems[0].description2}`;
      } else {
        return '';
      }
    },
  },
  mounted() {
    
  },
  methods: {
    empty: _.isEmpty,
    getImagePath({ group }, size) {
      const imagePrefix = 'https://cdn.jfnet.de/carPerspective/';
      const imageSuffix = `/${size}-cc-xxxxxx.png`;
      return `${imagePrefix}${group}${imageSuffix}`;
    },
    getWheelImageBySize(image) {
      if (image.includes('https://cdn.jfnet.de/tpms')) {
        return getImageBySize(image, 72, 72);
      }

      return getImageBySize(image, 72, 72);
    },
    getTpmsImageBySize(image) {
      if (image.includes('https://cdn.jfnet.de/tpms')) {
        return getImageBySize(image, 72, 72);
      }

      return getImageBySize(image, 72, 72).replace('https://cdn.jfnet.de/', 'https://cdn.jfnet.de/tpms/');
    },
  },
};
</script>